// MarkdownComponents.js
import { Box } from '@chakra-ui/react';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { CopyIconButton } from '../CopyIcon';
import { scrollbarStyle } from '../../Styles/ScrollbarStyle';

export const markdownComponents = {
  p: ({ node, ...props }) => (
    <Box 
      as="p" 
      mb={2} 
      color="text-primary" 
      {...props} 
    />
  ),
  h1: ({ children, ...props }) => (
    <Box
      as="h1"
      fontSize="xl"
      fontWeight="bold"
      mb={2}
      color="text-primary"
      lineHeight="1.5"
      {...props}
    >
      {children}
    </Box>
  ),
  h2: ({ children, ...props }) => (
    <Box
      as="h2"
      fontSize="lg"
      fontWeight="bold"
      mb={2}
      color="text-primary"
      lineHeight="1.4"
      {...props}
    >
      {children}
    </Box>
  ),
  h3: ({ children, ...props }) => (
    <Box
      as="h3"
      fontSize="md"
      fontWeight="bold"
      mb={2}
      color="text-primary"
      lineHeight="1.3"
      {...props}
    >
      {children}
    </Box>
  ),
  ul: ({ node, ...props }) => (
    <Box 
      as="ul" 
      pl={4} 
      mb={2} 
      color="text-primary" 
      listStylePosition="inside" 
      {...props} 
    />
  ),
  ol: ({ node, ...props }) => (
    <Box 
      as="ol" 
      pl={6}  // Erhöhter Abstand für zweistellige Zahlen
      mb={2} 
      color="text-primary"
      sx={{
        listStyleType: 'decimal',
        '& > li': {
          display: 'list-item',
        },
        // Mindestbreite für Nummerierung
        '& > li::marker': {
          minWidth: '2ch',
          marginRight: '1ch',
          display: 'inline-block'
        }
      }}
      {...props} 
    />
  ),
  
  li: ({ node, children, ...props }) => (
    <Box 
      as="li" 
      mb={1} 
      color="text-primary"
      sx={{
        pl: '1ch',  // Angepasster Abstand
        display: 'list-item',
        marginLeft: '1ch',  // Extra Platz für zweistellige Zahlen
        '& > p': {
          display: 'inline',
        },
        '& > strong': {
          display: 'inline',
        }
      }}
      {...props}
    >
      {children}
    </Box>
  ),
  
  
  strong: ({ node, children, ...props }) => (
    <Box
      as="strong"
      fontWeight="bold"
      display="inline"
      {...props}
    >
      {children}
    </Box>
  ),
  
  code: ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || '');
    return !inline && match ? (
      <Box
        mb={3}
        position="relative"
        overflow="auto"
        css={scrollbarStyle}
        borderRadius="md"
      >
        <SyntaxHighlighter
          style={tomorrow}
          language={match[1]}
          PreTag="div"
          customStyle={{
            margin: 0,
            padding: '1em',
            backgroundColor: 'var(--chakra-colors-bg-elevated)',
            minWidth: 'fit-content', // Wichtig für horizontales Scrollen
            lineHeight: 1.5
          }}
          codeTagProps={{
            style: {
              display: 'block',
              overflow: 'visible'
            }
          }}
          {...props}
        >
          {String(children).replace(/\n$/, '')}
        </SyntaxHighlighter>
        <CopyIconButton
          content={String(children)}
          size="xs"
          position="absolute"
          right="8px"
          top="8px"
        />
      </Box>
    ) : (
      <Box
        as="code"
        display="inline-block"
        p={1}
        bg="bg-elevated"
        color="text-primary"
        borderRadius="md"
        fontFamily="monospace"
        fontSize="sm"
        whiteSpace="pre-wrap"
        wordBreak="break-word"
        {...props}
      >
        {children}
      </Box>
    );
  },
  a: ({ node, ...props }) => (
    <Box
      as="a"
      color="accent-strong"
      textDecoration="underline"
      _hover={{ 
        color: "accent-strong",
        filter: "brightness(130%)",
        transform: "translateY(-1px)" 
      }}
      _active={{
        transform: "translateY(0)",
        filter: "brightness(100%)"
      }}
      transition="all 0.2s"
      {...props}
    />
  ),
  blockquote: ({ node, ...props }) => (
    <Box
      as="blockquote"
      borderLeftWidth="2px"
      borderLeftColor="border-subtle"
      pl={2}
      py={1}
      mb={2}
      color="text-secondary"
      fontStyle="italic"
      bg="bg-elevated"
      borderRadius="md"
      {...props}
    />
  ),
};


// // MarkdownComponents.js
// import { Box } from '@chakra-ui/react';
// import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
// import { tomorrow } from 'react-syntax-highlighter/dist/esm/styles/prism';
// import { CopyIconButton } from '../CopyIcon';


// export const markdownComponents = {
//     p: ({ node, ...props }) => (
//       <Box 
//         as="p" 
//         mb={2} 
//         color="text-primary" 
//         {...props} 
//       />
//     ),
//       h1: ({ children, ...props }) => (
//     <Box
//       as="h1"
//       display="flex"
//       alignItems="center"
//       fontSize="xl"
//       fontWeight="bold"
//       mb={2}
//       color="text-primary"
//       sx={{
//         '& > *': {
//           fontWeight: 'inherit !important',
//           fontSize: 'inherit !important'
//         }
//       }}
//       {...props}
//     >
//       {children}
//     </Box>
//   ),
//   h2: ({ children, ...props }) => (
//     <Box
//       as="h2"
//       display="flex"
//       alignItems="center"
//       fontSize="lg"
//       fontWeight="bold"
//       mb={2}
//       color="text-primary"
//       sx={{
//         '& > *': {
//           fontWeight: 'inherit !important', 
//           fontSize: 'inherit !important'
//         }
//       }}
//       {...props}
//     >
//       {children}
//     </Box>
//   ),
//   h3: ({ children, ...props }) => (
//     <Box
//       as="h3"
//       display="flex"
//       alignItems="center"
//       fontSize="md"
//       fontWeight="bold"
//       mb={2}
//       color="text-primary"
//       sx={{
//         '& > *': {
//           fontWeight: 'inherit !important',
//           fontSize: 'inherit !important'
//         }
//       }}
//       {...props}
//     >
//       {children}
//     </Box>
//   ),
//     ul: ({ node, ...props }) => (
//       <Box 
//         as="ul" 
//         pl={4} 
//         mb={2} 
//         color="text-primary" 
//         listStylePosition="inside" 
//         {...props} 
//       />
//     ),
//     ol: ({ node, ...props }) => (
//       <Box 
//         as="ol" 
//         pl={4} 
//         mb={2} 
//         color="text-primary" 
//         listStylePosition="inside" 
//         {...props} 
//       />
//     ),
//     li: ({ node, ...props }) => (
//       <Box 
//         as="li" 
//         mb={1} 
//         color="text-primary" 
//         pl={1} 
//         {...props} 
//       />
//     ),
//     code: ({ node, inline, className, children, ...props }) => {
//       const match = /language-(\w+)/.exec(className || '');
//       return !inline && match ? (
//         <Box mb={3} position="relative">
//           <SyntaxHighlighter
//             style={tomorrow}
//             language={match[1]}
//             PreTag="div"
//             customStyle={{
//               margin: '0.5em 0',
//               borderRadius: '0.3em',
//               padding: '0.5em',
//               paddingRight: '2em',
//               backgroundColor: 'var(--chakra-colors-bg-elevated)',
//             }}
//             {...props}
//           >
//             {String(children).replace(/\n$/, '')}
//           </SyntaxHighlighter>
//           <CopyIconButton 
//             content={String(children)} 
//             size="xs"
//             top={2}
//             right={2}
//           />
//         </Box>
//       ) : (
//         <Box
//           as="code"
//           display="inline-block"
//           p={1}
//           bg="bg-elevated"
//           color="text-primary"
//           borderRadius="md"
//           fontFamily="monospace"
//           fontSize="sm"
//           whiteSpace="pre-wrap"
//           wordBreak="break-word"
//           {...props}
//         >
//           {children}
//         </Box>
//       );
//     },
//     a: ({ node, ...props }) => (
//       <Box
//         as="a"
//         color="accent-strong"
//         textDecoration="underline"
//         _hover={{ 
//           color: "accent-strong",
//           filter: "brightness(130%)",
//           transform: "translateY(-1px)" 
//         }}
//         _active={{
//           transform: "translateY(0)",
//           filter: "brightness(100%)"
//         }}
//         transition="all 0.2s"
//         {...props}
//       />
//     ),
//     blockquote: ({ node, ...props }) => (
//       <Box
//         as="blockquote"
//         borderLeftWidth="2px"
//         borderLeftColor="border-subtle"
//         pl={2}
//         py={1}
//         mb={2}
//         color="text-secondary"
//         fontStyle="italic"
//         bg="bg-elevated"
//         borderRadius="md"
//         {...props}
//       />
//     ),
//   };
// ResearchDashboard.js
import React, { useState, useEffect } from 'react';
import { 
  Box, 
  Select, 
  VStack,
  Spinner,
  Text,
  IconButton,
  useBreakpointValue,
  Menu,
  MenuButton,
  MenuList,
  MenuItem,
  Button,
  AlertDialog,
  AlertDialogBody,
  AlertDialogFooter,
  AlertDialogHeader,
  AlertDialogContent,
  AlertDialogOverlay,
  HStack
} from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon,ChevronDownIcon, DeleteIcon } from '@chakra-ui/icons';
import ChatComponent from '../ChatComponent';
import api from '../../services/api';
import { useAuth } from '../../contexts/AuthContext';
import { scrollbarStyle } from '../Styles/ScrollbarStyle';
import { format } from 'date-fns';


const ResearchDashboard = () => {
  const [researchResults, setResearchResults] = useState([]);
  const [selectedResearch, setSelectedResearch] = useState(null);
  const [selectedPdfAnalysis, setSelectedPdfAnalysis] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const { user } = useAuth();
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);
  const [researchToDelete, setResearchToDelete] = useState(null);
  const cancelRef = React.useRef();

  const isMobile = useBreakpointValue({ base: true, md: false });

  // Automatically collapse sidebar on mobile
  useEffect(() => {
    setIsSidebarOpen(!isMobile);
  }, [isMobile]);

  useEffect(() => {
    const fetchResearchResults = async () => {
      if (!user) return;
      setIsLoading(true);
      try {
        const response = await api.get(`/workflows/research-results/user/${user}/`);
        setResearchResults(response.data.results);
      } catch (error) {
        console.error('Fehler beim Laden der Research Results:', error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchResearchResults();
  }, [user]);

  const loadResearch = async (uuid) => {
    setIsLoading(true);
    try {
      const response = await api.get(`/workflows/research/${uuid}/`);
      setSelectedResearch(response.data);
      setSelectedPdfAnalysis(null);
    } catch (error) {
      console.error('Fehler beim Laden der Research:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleDelete = async (research) => {
    setResearchToDelete(research);
    setIsDeleteDialogOpen(true);
  };

  const confirmDelete = async () => {
    try {
      await api.delete(`/workflows/research/${researchToDelete.uuid}/`);
      setResearchResults(researchResults.filter(r => r.uuid !== researchToDelete.uuid));
      if (selectedResearch?.uuid === researchToDelete.uuid) {
        setSelectedResearch(null);
        setSelectedPdfAnalysis(null);
      }
    } catch (error) {
      console.error('Fehler beim Löschen:', error);
    } finally {
      setIsDeleteDialogOpen(false);
      setResearchToDelete(null);
    }
  };
  
  return (
    <Box 
      h="100%" 
      w="100%" 
      display="flex" 
      flexDirection="column" 
      bg="bg-page"
      position="relative" 
      overflow="hidden"
      p={4}
      gap={4}
    >
      {/* Header Area */}
      <Box display="flex" alignItems="center" gap={4}>
        <Box flex="1" maxW={{ base: "100%", md: "300px" }}>
          <Menu>
            <MenuButton 
              as={Button} 
              rightIcon={<ChevronDownIcon />}
              w="full"
              bg="bg-page"
              borderWidth="2px"
              borderColor="border-subtle"
              _hover={{ borderColor: "border-hover" }}
            >
              {selectedResearch?.title || "Research auswählen"}
            </MenuButton>
            <MenuList>
              {researchResults.slice(0, 5).map((research) => (
                <MenuItem key={research.uuid}>
                  <HStack w="full" justify="space-between">
                    <Text onClick={() => loadResearch(research.uuid)}>
                      {research.title}
                    </Text>
                    <IconButton
                      icon={<DeleteIcon />}
                      size="sm"
                      variant="ghost"
                      colorScheme="red"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDelete(research);
                      }}
                      aria-label="Delete research"
                    />
                  </HStack>
                </MenuItem>
              ))}
            </MenuList>
          </Menu>

          {/* Alert Dialog */}
          <AlertDialog
            isOpen={isDeleteDialogOpen}
            leastDestructiveRef={cancelRef}
            onClose={() => setIsDeleteDialogOpen(false)}
          >
            <AlertDialogOverlay>
              <AlertDialogContent>
                <AlertDialogHeader fontSize="lg" fontWeight="bold">
                  Research löschen
                </AlertDialogHeader>

                <AlertDialogBody>
                  Sind Sie sicher? Sie können diese Aktion nicht rückgängig machen.
                  Die Research "{researchToDelete?.title}" wird komplett mit allen zugehörigen Analysen gelöscht.
                </AlertDialogBody>

                <AlertDialogFooter>
                  <Button ref={cancelRef} onClick={() => setIsDeleteDialogOpen(false)}>
                    Abbrechen
                  </Button>
                  <Button colorScheme="red" onClick={confirmDelete} ml={3}>
                    Löschen
                  </Button>
                </AlertDialogFooter>
              </AlertDialogContent>
            </AlertDialogOverlay>
          </AlertDialog>
        </Box>

        {isMobile && (
          <IconButton
            icon={isSidebarOpen ? <ChevronLeftIcon /> : <ChevronRightIcon />}
            onClick={() => setIsSidebarOpen(!isSidebarOpen)}
            variant="ghost"
            aria-label="Toggle sidebar"
          />
        )}
      </Box>

      {/* Main Content Area */}
<Box 
  flex="1" 
  display="flex" 
  position="relative"
  overflow="hidden"
>
  {/* Sidebar */}
  <Box
    position="absolute"
    left={0}
    top={0}
    h="full"
    w={isSidebarOpen ? "300px" : "0"}
    transition="width 0.3s ease"
    zIndex={2}
    bg="bg-page"
    overflow="hidden"
  >
    <Box
      w="300px"
      h="full"
    >
      <VStack 
        w="full"
        h="full" 
        spacing={2}
        p={2}
        overflow="auto"
        align="stretch"
        css={scrollbarStyle}
      >
        {selectedResearch?.pdf_analyses.map((analysis) => (
          <Box
            key={analysis.uuid}
            p={3}
            cursor="pointer"
            borderRadius="lg"
            bg="bg-page"
            borderWidth="2px"
            borderColor={selectedPdfAnalysis?.uuid === analysis.uuid 
              ? "border-hover" 
              : "border-subtle"}
            _hover={{ 
              bg: "bg-elevated",
              borderColor: "border-hover",
              transform: 'translateY(-1px)',
            }}
            transition="all 0.2s"
            onClick={() => setSelectedPdfAnalysis(analysis)}
          >
            {/* PDF Metadata Header */}
            <Box mb={2}>
              <Text 
                fontSize="md" 
                fontWeight="semibold"
                color="text-primary"
                noOfLines={1}
              >
                {analysis.title || 'Untitled PDF'}
              </Text>
              
              {analysis.metadata && (
                <Box mt={1} fontSize="xs" color="text-secondary">
                  <Text noOfLines={1}>
                    Authors: {analysis.metadata.authors?.join(', ')}
                  </Text>
                  <Text>
                    Published: {format(new Date(analysis.metadata.published), 'PP')}
                  </Text>
                  <Text noOfLines={1}>
                    Categories: {analysis.metadata.categories?.join(', ')}
                  </Text>
                </Box>
              )}
            </Box>

            {/* First message preview */}
            {analysis.chat_history && analysis.chat_history.length > 0 && (
              <Box 
                mt={2} 
                pt={2}
                borderTopWidth="1px"
                borderTopColor="border-subtle"
              >
                <Text 
                  fontSize="sm"
                  color="text-secondary"
                  noOfLines={2}
                >
                  {analysis.chat_history[0].content}
                </Text>
              </Box>
            )}
          </Box>
        ))}
      </VStack>
    </Box>
  </Box>

  {/* Chat Container */}
  <Box
    flex="1"
    pl={isSidebarOpen ? "300px" : 0}
    transition="padding-left 0.3s ease"
    display="flex"
    justifyContent="center"
  >
          <Box 
            w="full" 
            maxW="65ch"
            transition="width 0.3s ease"
          >
            {isLoading ? (
              <Box p={4} h="full" display="flex" alignItems="center" justifyContent="center">
                <Spinner />
              </Box>
            ) : selectedPdfAnalysis ? (
              <ChatComponent 
                // selectedChatId={selectedPdfAnalysis.uuid}
                initialMessages={selectedPdfAnalysis.chat_history}
              />
            ) : (
              <Box p={4} h="full" display="flex" alignItems="center" justifyContent="center">
                <Text color="text-secondary">Wählen Sie eine PDF-Analyse aus</Text>
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </Box>
  );
};

export default ResearchDashboard;
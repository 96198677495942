import React from 'react';
import { Text, Box } from '@chakra-ui/react';

export const PlaceholderText = () => (
  <Box 
    position="absolute" 
    right={{ base: '60px', sm: '120px' }}
    bottom="50%" 
    transform="translateY(50%)" 
    pointerEvents="none"
    display={{ base: 'none', sm: 'block' }}  // Hide on mobile, show on sm and up
  >
    <Text 
      color="text-primary" 
      opacity={0.7}
      fontSize="sm"
    >
      Tell me what you need
    </Text>
  </Box>
);

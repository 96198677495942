import React from 'react';
import { Flex, Box, Text, useBreakpointValue } from '@chakra-ui/react';
import { LogoImage } from './ChatBotLogo';
import {MarkdownContent} from './markdown/MarkdownContent'
import { CopyIconButton } from './CopyIcon';

export const MessageBubble = React.memo(({ message, isUser }) => {
  const showLogo = useBreakpointValue({ base: false, md: true });
  const bubbleWidth = useBreakpointValue({ 
    base: "calc(100% - 10px)", 
    md: "calc(85% - 50px)" 
  });
  const headerPadding = useBreakpointValue({ base: 1.5, md: 3 });
  const nameWidth = useBreakpointValue({ base: "4rem", md: "7rem" });

  // Content-Referenz stabilisieren
  const stableContent = React.useMemo(() => message.content, [message.content]);

  return (
    <Flex
      alignItems="flex-start"
      position="relative"
      width="100%"
      onClick={(e) => e.stopPropagation()}
    >
      {!isUser && showLogo && <LogoImage />}
      <Box 
        alignSelf={isUser ? 'flex-end' : 'flex-start'}
        maxWidth={bubbleWidth}
        minWidth="8rem"
        width="fit-content"
        ml={isUser ? 'auto' : '0'}
        fontSize="sm"
        position="relative"
        overflow="visible"  // Geändert von hidden zu visible
        borderRadius="lg"
        borderWidth="2px"
        borderColor={isUser ? 'border-subtle' : 'border-subtle'}
        pointerEvents="auto"
        _hover={{
          borderColor: isUser ? 'border-subtle' : 'accent-subtle'
        }}
        transition="all 0.2s ease"
      >
        {/* Header Section */}
        <Flex
          bg="bg-elevated"
          px={headerPadding}
          py={1.5}
          borderTopRadius="lg"
          justifyContent="space-between"
          alignItems="center"
          borderBottom="0px"
          borderColor="border-subtle"
          position="relative"
          zIndex="2"
        >
          <Text
            fontWeight="bold"
            color={isUser ? 'text-primary' : 'accent-strong'}
            minWidth={nameWidth}
          >
            {isUser ? 'You' : 'Knowlen'}
          </Text>
          <CopyIconButton 
            content={stableContent} 
            position="relative"
            zIndex="3"
          />
        </Flex>
        
        {/* Content Section */}
        <Box 
          bg={isUser ? 'message-user' : 'message-bot'}
          color="text-primary"
          py={2}
          px={headerPadding}
          position="relative"
          zIndex="1"
        >
          <Box
            position="relative"
            zIndex="1"
            onClick={(e) => e.stopPropagation()}
            pointerEvents="auto"
          >
            <MarkdownContent 
              content={stableContent}
              key={`md-${message.id}`} // Stabiler Key
            />
          </Box>
        </Box>
      </Box>
      {isUser && showLogo && <Box width="40px" />}
    </Flex>
  );
}, (prev, next) => 
  prev.message.content === next.message.content && 
  prev.isUser === next.isUser
);

// export const MessageBubble = React.memo(({ message, isUser }) => {
//   const showLogo = useBreakpointValue({ base: false, md: true });
//   const bubbleWidth = useBreakpointValue({ 
//     base: "calc(100% - 10px)", 
//     md: "calc(85% - 50px)" 
//   });
//   const headerPadding = useBreakpointValue({ base: 1.5, md: 3 });
//   const nameWidth = useBreakpointValue({ base: "4rem", md: "7rem" });

//   // Content-Referenz stabilisieren
//   const stableContent = React.useMemo(() => message.content, [message.content]);
  
//   return (
//     <Flex alignItems="flex-start">
//       {!isUser && showLogo && <LogoImage />}
//       <Box 
//         alignSelf={isUser ? 'flex-end' : 'flex-start'}
//         maxWidth={bubbleWidth}
//         minWidth="8rem"  // Minimale Breite für sehr kurze Nachrichten
//         width="fit-content"  // Passt sich an den Inhalt an
//         ml={isUser ? 'auto' : '0'}
//         fontSize="sm"
//         position="relative"
//         overflow="hidden"
//         borderRadius="lg"
//         borderWidth="2px"
//         borderColor={isUser ? 'border-subtle' : 'border-subtle'}
//         pointerEvents="auto"  // Neu hinzugefügt
//         onClick={(e) => e.stopPropagation()} // Neu hinzugefügt
//       >
//         <Flex
//           bg="bg-elevated"
//           px={headerPadding}
//           py={1.5}
//           borderTopRadius="lg"
//           justifyContent="space-between"
//           alignItems="center"
//           borderBottom="0px"
//           borderColor="border-subtle"
//         >
//           <Text
//             fontWeight="bold"
//             color={isUser ? 'text-primary' : 'accent-strong'}
//             minWidth={nameWidth}
//           >
//             {isUser ? 'You' : 'Knowlen'}
//           </Text>
//           <CopyIconButton content={message.content} />
//         </Flex>
        
//         <Box 
//           bg={isUser ? 'message-user' : 'message-bot'}
//           color="text-primary"
//           py={2}
//           px={headerPadding}
//         >
//           <MarkdownContent 
//             content={stableContent} 
//             key={message.id || 'streaming'}  // Stabiler Key
//           />
//         </Box>
//       </Box>
//       {isUser && showLogo && <Box width="40px" />}
//     </Flex>
//   );
// });

export default MessageBubble;
// App.js
import React, { useState, useEffect } from 'react';
import { ChakraProvider } from '@chakra-ui/react';
import { GoogleOAuthProvider } from '@react-oauth/google';
import { AuthProvider } from './contexts/AuthContext';
import { WebSocketProvider } from './contexts/WebSocketContext';
import { ChatProvider } from './contexts/ChatContext';
import {SearchProvider} from './contexts/SearchContext';
import { WebSocketMessageProvider } from './contexts/WebSocketMessageContext';
import AppLayout from './components/Layout/AppLayout';
import theme from './themes/theme';
import { CONFIG } from './config';
import './global.css';
import { use } from 'react';
import { initializeCSRF } from './services/api';
import {ThinkingProvider} from './contexts/ThinkingContext';

function App() {
  const [activeTab, setActiveTab] = useState('Research');
  const [selectedChatId, setSelectedChatId] = useState(null);

  useEffect(() => {
    initializeCSRF();
  }, []);

  const handleSelectChat = (chatId) => {
    setSelectedChatId(chatId);
    setActiveTab('Chat');
  };

  useEffect(() => {
    console.log('Google Client ID:', CONFIG.GOOGLE_CLIENT_ID);
    console.log('Raw env variable:', process.env.REACT_APP_GOOGLE_CLIENT_ID);
    console.log('base url:', CONFIG.API_BASE_URL);
    console.log('websocket url:', CONFIG.WEBSOCKET_URL);
  }, []);

  return (
    <ChakraProvider theme={theme}>
      <GoogleOAuthProvider clientId={CONFIG.GOOGLE_CLIENT_ID}>
        <AuthProvider>
          <WebSocketProvider>
            {/* <ThinkingProvider> */}
              <ChatProvider>
                <SearchProvider>
                  <WebSocketMessageProvider>
                    <AppLayout
                      activeTab={activeTab}
                      selectedChatId={selectedChatId}
                      onTabChange={setActiveTab}
                      onSelectChat={handleSelectChat}
                    />
                  </WebSocketMessageProvider>
                </SearchProvider>
              </ChatProvider>
            {/* </ThinkingProvider> */}
          </WebSocketProvider>
        </AuthProvider>
      </GoogleOAuthProvider>
    </ChakraProvider>
  );
}

export default App;
// components/CorporateLogo.js
import React from 'react';
import { Box, Image, Text, VStack } from '@chakra-ui/react';
import PainlessLogo from '../../icons/Painless_Logo_small.png';

const ColapsedLogo = () => {
return (
    <Box 
        width="100%" 
        display="flex" 
        justifyContent="center" 
        py={4}
        borderBottom="none"
        borderColor="border-color"
    >
        <Image 
            src={PainlessLogo} 
            alt="Painless Papers Logo"
            maxWidth="100%"
            height="auto"
        />
    </Box>
);
};

export default ColapsedLogo;
import React, { useRef, useMemo } from 'react';
import { Box } from '@chakra-ui/react';
import ReactMarkdown from 'react-markdown';
import { ThinkingBox } from './ThinkingBox';
import { markdownComponents } from './MarkdownComponents';
import { parseContent } from './ContentParser';
import { scrollbarStyle } from '../../Styles/ScrollbarStyle';

export const MarkdownContent = ({ content }) => {
  const blockIdsRef = useRef(new Map());
  
  const parsedContent = useMemo(() => 
    parseContent(content, blockIdsRef),
    [content]
  );

  return (
    <Box 
      className="chat-container"
      overflowX="auto" 
      width="100%"
      css={scrollbarStyle}
    >
      {parsedContent.map((part) => {
        if (part.type === 'think') {
          return (
            <ThinkingBox 
              key={part.key}
              blockId={part.key}
              isComplete={part.isComplete}
            >
              <ReactMarkdown components={markdownComponents}>
                {part.content}
              </ReactMarkdown>
            </ThinkingBox>
          );
        }
        return (
          <ReactMarkdown
            key={part.key}
            components={markdownComponents}
          >
            {part.content}
          </ReactMarkdown>
        );
      })}
    </Box>
  );
};

import { Box, Text, Flex, Collapse } from '@chakra-ui/react';
import { keyframes } from '@emotion/react';
import { useThinkingState } from '../../../hooks/useThinkingState';
import { scrollbarStyle } from '../../Styles/ScrollbarStyle';
import { useRef, useEffect, useState } from 'react';

export const ThinkingBox = ({ isComplete, children, blockId }) => {
  const { isOpen, onToggle } = useThinkingState(blockId);
  const contentRef = useRef(null);
  const [scrollState, setScrollState] = useState({ top: false, bottom: false });

  // Glow-Effect Animation
  const glowKeyframes = keyframes`
    0% { 
      opacity: 0;
      transform: translateX(-100%) skewX(-20deg);
    }
    50% { 
      opacity: 0.4;
    }
    100% { 
      opacity: 0;
      transform: translateX(200%) skewX(-20deg);
    }
  `;

  const checkScrollPosition = () => {
    if (contentRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
      setScrollState({
        top: scrollTop > 0,
        bottom: scrollTop + clientHeight < scrollHeight
      });
    }
  };

  useEffect(() => {
    const contentElement = contentRef.current;
    if (contentElement) {
      contentElement.addEventListener('scroll', checkScrollPosition);
      checkScrollPosition();
    }
    return () => contentElement?.removeEventListener('scroll', checkScrollPosition);
  }, [isOpen]);

  return (
    <Box
      position="relative"
      bg="bg-elevated"
      borderRadius="md"
      my={2}
      borderLeft="2px solid"
      borderColor={isComplete ? "accent-subtle" : "accent-strong"}
      overflow="hidden"
      _before={!isComplete ? {
        content: '""',
        position: 'absolute',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        bg: 'linear-gradient(90deg, transparent, var(--chakra-colors-accent-strong), transparent)',
        opacity: 0.15,
        zIndex: 1,
        animation: `${glowKeyframes} 2.5s infinite`,
        pointerEvents: 'none'
      } : undefined}
    >
      <Flex
        onClick={onToggle}
        cursor="pointer"
        w="full"
        px={3}
        py={2}
        alignItems="center"
        bg="bg-elevated"
        _hover={{ bg: 'whiteAlpha.50' }}
        userSelect="none"
        position="relative"
        overflow="hidden"
      >
        {/* Glow Bar */}
        {!isComplete && (
          <Box
            position="absolute"
            top={0}
            left={0}
            h="full"
            w="20px"
            bg="linear-gradient(90deg, var(--chakra-colors-accent-strong), transparent)"
            opacity={0.3}
            animation={`${glowKeyframes} 2s infinite`}
            transform="skewX(-20deg)"
            pointerEvents="none"
          />
        )}

        <Text
          fontWeight="bold"
          fontSize="sm"
          color={isComplete ? "text-primary" : "accent-strong"}
          position="relative"
          zIndex={2}
          letterSpacing="0.5px"
        >
          Thinking
        </Text>
      </Flex>

      <Collapse in={isOpen}>
        <Box
          ref={contentRef}
          p={3}
          maxH="400px"
          overflowY="auto"
          css={scrollbarStyle}
          data-scroll-top={scrollState.top}
          data-scroll-bottom={scrollState.bottom}
        >
          {children}
        </Box>
      </Collapse>
    </Box>
  );
};

// import { Box, Text, Flex, Collapse } from '@chakra-ui/react';
// import { keyframes } from '@emotion/react';
// import { useThinkingState } from '../../../hooks/useThinkingState';
// import { scrollbarStyle } from '../../Styles/ScrollbarStyle';
// import { useRef, useEffect, useState } from 'react';

// export const ThinkingBox = ({ isComplete, children, blockId }) => {
//   const { isOpen, onToggle } = useThinkingState(blockId);
//   const contentRef = useRef(null);
//   const [scrollState, setScrollState] = useState({ top: false, bottom: false });

//   const dotsKeyframes = keyframes`
//     0% { content: '.'; }
//     33% { content: '..'; }
//     66% { content: '...'; }
//     100% { content: '.'; }
//   `;

//   // Scroll Position Check
//   const checkScrollPosition = () => {
//     if (contentRef.current) {
//       const { scrollTop, scrollHeight, clientHeight } = contentRef.current;
//       setScrollState({
//         top: scrollTop > 0,
//         bottom: scrollTop + clientHeight < scrollHeight
//       });
//     }
//   };

//   useEffect(() => {
//     const contentElement = contentRef.current;
//     if (contentElement) {
//       contentElement.addEventListener('scroll', checkScrollPosition);
//       // Initial check
//       checkScrollPosition();
//     }

//     return () => {
//       if (contentElement) {
//         contentElement.removeEventListener('scroll', checkScrollPosition);
//       }
//     };
//   }, [isOpen]); // Re-run when isOpen changes

//   return (
//     <Box
//       position="relative"
//       bg="bg-elevated"
//       borderRadius="md"
//       my={2}
//       borderLeft="2px solid"
//       borderColor={isComplete ? "accent-subtle" : "accent-strong"}
//       overflow="hidden"
//     >
//       <Flex
//         onClick={onToggle}
//         cursor="pointer"
//         w="full"
//         px={3}
//         py={2}
//         alignItems="center"
//         bg="bg-elevated"
//         _hover={{ bg: 'whiteAlpha.100' }}
//         userSelect="none"
//       >
//         <Text
//           fontWeight="bold"
//           fontSize="sm"
//           color={isComplete ? "text-primary" : "accent-strong"}
//         >
//           Thinking
//           {!isComplete && (
//             <Box 
//               as="span" 
//               css={{
//                 '&::after': {
//                   content: '"..."',
//                   animation: `${dotsKeyframes} 1.5s infinite`
//                 }
//               }}
//             />
//           )}
//         </Text>
//       </Flex>

//       <Collapse in={isOpen}>
//         <Box
//           ref={contentRef}
//           p={3}
//           maxH="400px"
//           overflowY="auto"
//           css={scrollbarStyle}
//           data-scroll-top={scrollState.top}
//           data-scroll-bottom={scrollState.bottom}
//         >
//           {children}
//         </Box>
//       </Collapse>
//     </Box>
//   );
// };
// components/LoginWindow/LoginButton.js
import React from 'react';
import { Button, useDisclosure, Icon, Text } from '@chakra-ui/react';
import { FaUser } from 'react-icons/fa';
import CustomModal from '../ModalComponent/CustomModal';
import GoogleLoginButton from '../GoogleLoginButton';
import { useAuth } from '../../contexts/AuthContext';
import LogoutButton from '../CorporateButtons/LogoutButton';

const LoginButton = ({ isCollapsed }) => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { user, logout } = useAuth();

  const handleLogout = () => {
    console.log("Logout initiated");
    logout();
    console.log("Logout completed");
  };
  return user ? (
    <LogoutButton 
      onClick={logout} 
      isCollapsed={isCollapsed} 
    />
  ) : (
    <>
      <Button
        onClick={onOpen}
        variant="secondary"
        w={isCollapsed ? "40px" : "auto"}
        minW={isCollapsed ? "40px" : "120px"}
        h="40px"
        p={isCollapsed ? "8px" : "0 12px"}
        display="flex"
        alignItems="center"
        justifyContent="center" // Zentriert den gesamten Inhalt
      >
        <Icon 
          as={FaUser} 
          boxSize={isCollapsed ? "20px" : "16px"}
          mr={isCollapsed ? "0" : "2"}
        />
        {!isCollapsed && (
          <Text textAlign="center">Login</Text>
        )}
      </Button>
      <CustomModal isOpen={isOpen} onClose={onClose} title="Login">
        <GoogleLoginButton />
      </CustomModal>
    </>
  );
};

export default LoginButton;

// components/ChatInputArea/ChatTextarea.js
import React from 'react';
import { Textarea } from '@chakra-ui/react';
import { useTextareaLogic } from '../../hooks/TextInputArea/useTextareaLogic';
import { scrollbarStyle } from '../Styles/ScrollbarStyle';
import { useScrollIndicators } from '../../hooks/useScrollIndicators';

export const ChatTextarea = ({ 
  inputMessage, 
  setInputMessage, 
  handleKeyPress, 
  pl, 
  pr, 
  onFocus, 
  onBlur 
}) => {
  const textareaRef = useTextareaLogic(inputMessage);
  const scrollRef = useScrollIndicators();
  // Combine refs
  const combinedRef = (element) => {
    textareaRef.current = element;
    scrollRef.current = element;
  };
  return (
    <Textarea 
      ref={combinedRef}
      value={inputMessage} 
      onChange={(e) => setInputMessage(e.target.value)} 
      placeholder=" "
      onKeyPress={handleKeyPress}
      onFocus={onFocus}
      onBlur={onBlur}
      bg="input-bg"
      color="text-primary"
      borderColor='accent-subtle'
      borderWidth='2px'
      _hover={{
        borderColor: 'accent-strong'
      }}
      _focus={{
        outline: "none",
        boxShadow: "none",
        borderColor: 'accent-strong'
      }}
      css={{
        ...scrollbarStyle,
        borderRadius: '2rem',
        paddingLeft: pl,
        paddingRight: `calc(${pr} + 12px)`, // Extra Padding für Scrollbar
        paddingTop: '1rem',
        paddingBottom: '1px',
        lineHeight: '1.4rem',
        transition: 'border-color 0.2s ease-in-out',
        '&::-webkit-scrollbar-track': {
          margin: '2rem 0',    // Gibt Platz oben und unten
          marginRight: '40px',  // Verschiebt die Scrollbar nach links
        },
      }}
      minH="60px"
      maxH="200px"
      overflow="auto"
      resize="none"
      rows={1}
    />
  );
};
// components/Layout/AppLayout.js
import React, { useState, useEffect } from 'react';
import { Flex, Box, useColorModeValue, useBreakpointValue } from '@chakra-ui/react';
import Sidebar from '../Sidebar/Sidebar';
import MainContent from '../MainContent/MainContent';

const AppLayout = ({ activeTab, selectedChatId, onTabChange, onSelectChat }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(true);
  const bgColor = useColorModeValue('bg-elevated', 'bg-elevated');
  const contentBg = useColorModeValue('bg-page', 'bg-page');
  const isMobile = useBreakpointValue({ base: true, md: false });

  useEffect(() => {
    setIsSidebarOpen(!isMobile);
  }, [isMobile]);

  return (
    <Flex 
      height="100vh" 
      bg={bgColor}
      overflow="hidden" // Verhindert Scrolling auf der äußeren Ebene
    >
      {/* Sidebar Container */}
      <Box
        position="fixed"
        left={0}
        top={0}
        height="100vh"
        width={isSidebarOpen ? "12rem" : "2rem"} // Angepasst an die Sidebar-Größen
        transition="all 0.3s ease"
        zIndex={20}
        bg={bgColor}
      >
        <Sidebar
          activeTab={activeTab}
          onTabChange={onTabChange}
          onSelectChat={onSelectChat}
          isCollapsed={!isSidebarOpen}
          onToggle={() => setIsSidebarOpen(!isSidebarOpen)}
        />
      </Box>

      {/* Main Content Container */}
      <Flex 
        flex={1} 
        flexDirection="column"
        marginLeft={isSidebarOpen ? "12rem" : "2rem"} // Angepasst an die Sidebar-Größen
        transition="all 0.3s ease"
        width={isSidebarOpen ? "calc(100% - 15rem)" : "calc(100% - 4rem)"}
        p={4}
        height="100vh"
        overflow="hidden" // Verhindert zusätzliches Scrolling
      >
        <Box 
          flex={1} 
          position="relative" 
          bg={contentBg}
          borderRadius="xl"
          boxShadow="0 0 10px rgba(0, 0, 0, 0.1)"
          border="1px solid"
          borderColor="border-subtle"
          overflow="hidden" // Kontrolliert das Overflow-Verhalten
        >
          <Box 
            height="100%" 
            p={4}
            overflowY="auto" // Nur vertikales Scrolling wenn nötig
            overflowX="hidden" // Kein horizontales Scrolling
          >
            <MainContent 
              activeTab={activeTab}
              selectedChatId={selectedChatId}
            />
          </Box>
        </Box>
      </Flex>
    </Flex>
  );
};

export default AppLayout;
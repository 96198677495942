// components/ChatContainer.js
import React from 'react';
import { Box } from '@chakra-ui/react';
import { MessageList } from './MessageList';
import { scrollbarStyle } from '../Styles/ScrollbarStyle';

export function ChatContainer({ chatContainerRef, handleScroll, messages, currentResponse }) {
  return (
    <Box 
      flex={1} 
      overflowY="auto" 
      ref={chatContainerRef}
      onScroll={handleScroll}
      position="relative"
      pb="10px"
      className="custom-scrollbar"
      css={scrollbarStyle}
      pointerEvents="auto"  // Neu hinzugefügt
      zIndex={1}           // Neu hinzugefügt
    >
      <MessageList messages={messages} currentResponse={currentResponse} />
    </Box>
  );
}
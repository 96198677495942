import React from 'react';
import { Button, useToast, Text } from '@chakra-ui/react';
import { CopyIcon } from '@chakra-ui/icons';

export const CopyIconButton = ({ content, ...props }) => {
  const toast = useToast();

  const handleCopy = () => {
    navigator.clipboard.writeText(content).then(() => {
      toast({
        title: "Code copied to clipboard",
        status: "success",
        duration: 2000,
        isClosable: true,
        variant: "subtle",
        position: "top",
        bg: "accent-subtle",
        color: "text-primary"
      });
    }).catch(err => {
      console.error('Failed to copy: ', err);
      toast({
        title: "Failed to copy code",
        status: "error",
        duration: 2000,
        isClosable: true,
        variant: "subtle",
        position: "top"
      });
    });
  };

  return (
    <Button
      leftIcon={<CopyIcon />}
      size="xs"
      aria-label="Copy code"
      onClick={handleCopy}
      position="absolute"
      top={0}
      right={0}
      bg="transparent"
      color="text-primary"
      px={2}
      height="24px"
      minW="auto"
      _hover={{ 
        bg: "bg-elevated",
        color: "accent-strong",
        transform: "translateY(-1px)"
      }}
      _active={{
        transform: "translateY(0px)"
      }}
      transition="all 0.2s"
      {...props}
    >
      <Text fontSize="xs" color="inherit">
        copy
      </Text>
    </Button>
  );
};

// // CopyIcon.js
// import React from 'react';
// import { HStack, IconButton, useToast, Text } from '@chakra-ui/react';
// import { CopyIcon } from '@chakra-ui/icons';

// export const CopyIconButton = ({ content, ...props }) => {
//   const toast = useToast();

//   const handleCopy = () => {
//     navigator.clipboard.writeText(content).then(() => {
//       toast({
//         title: "Code copied to clipboard",
//         status: "success",
//         duration: 2000,
//         isClosable: true,
//         variant: "subtle",
//         position: "top",
//         bg: "accent-subtle",
//         color: "text-primary"
//       });
//     }).catch(err => {
//       console.error('Failed to copy: ', err);
//       toast({
//         title: "Failed to copy code",
//         status: "error",
//         duration: 2000,
//         isClosable: true,
//         variant: "subtle",
//         position: "top"
//       });
//     });
//   };

//   return (
//     <HStack>
//       <IconButton
//         icon={<CopyIcon />}
//         size="xs"
//         aria-label="Copy code"
//         onClick={handleCopy}
//         position="absolute"
//         top={1}
//         right={1}
//         bg="transparent"
//         color="text-primary"
//         _hover={{ 
//           bg: "bg-elevated",
//           color: "accent-strong",
//           transform: "translateY(-1px)"
//         }}
//         _active={{
//           transform: "translateY(0px)"
//         }}
//         transition="all 0.2s"
//         {...props}
//       />
//       <Text fontSize="xs" color="text-secondary">
//         copy
//       </Text>
    
//     </HStack>
//   );
// };
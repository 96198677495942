import { useState, useCallback } from 'react';

export const useThinkingState = (blockId) => {
  const [isOpen, setIsOpen] = useState(false);

  const onToggle = useCallback((e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setIsOpen(prev => !prev);
  }, []);

  return { isOpen, onToggle };
};

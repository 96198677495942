// components/Sidebar/Sidebar.js
import React, { useEffect } from 'react';
import { Box, VStack, IconButton, useColorModeValue, Button } from '@chakra-ui/react';
import { ChevronLeftIcon, ChevronRightIcon } from '@chakra-ui/icons';


// components/Sidebar/Sidebar.js
import TabButton from './TabButton';
import ChatHistoryList from '../ChatHistoryList';
import CorporateLogo from './CorporateLogo';
import ColapsedLogo from './CollapsedLogo ';
import TUHHLogo from './IFPT';
import LoginButton from '../LoginWindow/LoginButton';
import SettingsButton from '../ModalComponent/SettingsButton';
import { useChatHistory } from '../../hooks/useChatHistory';
import { NewChatButton } from '../NewChatButton';

const Sidebar = ({ activeTab, onTabChange, onSelectChat, isCollapsed, onToggle }) => {
  const componentBg = useColorModeValue('bg-component', 'bg-component');
  const borderColor = useColorModeValue('border-color', 'border-color');
  const tabs = ['Research', 'Write', 'Review', 'Chat'];
  const { fetchChatHistories } = useChatHistory();
  
  useEffect(() => {
    // Lade die ChatHistory direkt wenn der Chat-Tab aktiv ist
    if (activeTab === 'Chat') {
      fetchChatHistories();
    }
  }, [activeTab]); // Abhängigkeit von activeTab
  return (
    <Box
      width={isCollapsed ? "2rem" : "13rem"}
      height="100vh"
      display="flex"
      flexDirection="column"
      bg='bg-elevated'
      borderRight="0px"
      mr={0}
      ml={isCollapsed ? 2: 0}
      borderColor='bg-elevated'
      position="relative"
      transition="width 0.3s ease"

    >
      {/* Logo Section */}
      <Box>
        {isCollapsed ? <ColapsedLogo /> : <CorporateLogo />}
        {/* Collapse Toggle Button unter dem Logo */}
        <Box 
          display="flex" 
          justifyContent="center" 
          mt={2} 
          mb={4} 
          px={2}
        >
          <Button
            onClick={onToggle}
            variant="ghost"
            size="sm"
            width={isCollapsed ? "40px" : "50%"}
            height="24px"
            borderRadius="full"
            color="text-primary"
            bg="bg-elevated-highlight"
            _hover={{
              bg: "accent-subtle",
              color: "accent-strong"
            }}
            display="flex"
            alignItems="center"
            justifyContent="center"
          >
            {isCollapsed ? 
              <ChevronRightIcon boxSize={5} /> : 
              <ChevronLeftIcon boxSize={5} />
            }
          </Button>
        </Box>
      </Box>

      {/* Navigation */}
      <VStack 
        spacing={1} 
        align="stretch" 
        p={2}
        flex="1"
        overflow="auto"
      >
        {tabs.map((tab) => (
          <TabButton
            key={tab}
            tab={tab}
            isActive={activeTab === tab}
            onClick={() => onTabChange(tab)}
            isCollapsed={isCollapsed}
          />
        ))}
        
        {activeTab === 'Chat' && !isCollapsed && (
          <NewChatButton />
        )}
        
        {activeTab === 'Chat' && !isCollapsed && (
          <Box mt={4}>
            <ChatHistoryList onSelectChat={onSelectChat} />
          </Box>
        )}
      </VStack>

      {/* Bottom Section */}
      <Box p={isCollapsed ? 2 : 4}>
        <Box 
          display="flex"
          flexDirection={isCollapsed ? "column" : "row"}
          gap={2}
          justifyContent="center"
          alignItems="center"
        >
          <LoginButton isCollapsed={isCollapsed} />
          <SettingsButton isCollapsed={isCollapsed} />
        </Box>
      </Box>

    </Box>
  );
}

export default Sidebar;

// // components/Sidebar/Sidebar.js
// import React, { useEffect } from 'react';
// import { Box, VStack, HStack, useColorModeValue } from '@chakra-ui/react';
// import TabButton from './TabButton';
// import ChatHistoryList from '../ChatHistoryList';
// import CorporateLogo from './CorporateLogo';
// import TUHHLogo from './IFPT';
// import LoginButton from '../LoginWindow/LoginButton';
// import SettingsButton from '../ModalComponent/SettingsButton';
// import { useChatHistory } from '../../hooks/useChatHistory';
// import { NewChatButton } from '../NewChatButton';

// const Sidebar = ({ activeTab, onTabChange, onSelectChat }) => {
//   const componentBg = useColorModeValue('bg-component', 'bg-component');
//   const borderColor = useColorModeValue('border-color', 'border-color');
//   const tabs = ['Research', 'Write', 'Review', 'Chat'];
//   const { fetchChatHistories } = useChatHistory();
  
//   useEffect(() => {
//     if (activeTab === 'Chat') {
//       fetchChatHistories();
//     }
//   }, [activeTab]);

//   return (
//     <Box
//       width="200px"
//       height="100vh"
//       display="flex"
//       flexDirection="column"
//       bg={componentBg}
//       borderRight="1px"
//       borderColor={borderColor}
//     >
//       {/* Top section with logo and navigation */}
//       <Box flex="1" overflow="auto">
//         <CorporateLogo />
//         {/* <TUHHLogo /> */}
//         <VStack 
//           spacing={1} 
//           align="stretch" 
//           p={2}
//           width="100%"
//         >
//           {tabs.map((tab) => (
//             <Box 
//               key={tab}
//               width="100%"
//               px={2}
//               py={1}
//               _hover={{
//                 cursor: 'pointer',
//               }}
//             >
//               <TabButton
//                 tab={tab}
//                 isActive={activeTab === tab}
//                 onClick={() => onTabChange(tab)}
//               />
//             </Box>
//           ))}
//           {activeTab === 'Chat' && (
//             <Box 
//               width="100%"
//               px={2}
//               py={1}
//             >
//               <NewChatButton />
//             </Box>
//           )}
//         </VStack>
//         {activeTab === 'Chat' && (
//           <Box mt={4} px={4}>
//             <ChatHistoryList onSelectChat={onSelectChat} />
//           </Box>
//         )}
//       </Box>

//       {/* Bottom section with login button */}
//       <Box 
//         p={4} 
//         borderTop="none"
//         display="flex"
//         justifyContent="center"
//         alignItems="center"
//         bg={componentBg}
//       > 
//         <Box display="flex" gap={2}>
//           <LoginButton />
//           <SettingsButton />
//         </Box>
//       </Box>
//     </Box>
//   );
// }

// export default Sidebar;
// contexts/ChatContext.js
import React, { createContext, useContext, useState, useCallback, useEffect } from 'react';
import { useAuth } from './AuthContext';
import { useWebSocket } from './WebSocketContext';
import { useChatState } from '../hooks/useChatState';
import api from '../services/api';
import { v4 as uuidv4 } from 'uuid';

const ChatContext = createContext();

export const ChatProvider = ({ children }) => {
  const [activeChat, setActiveChat] = useState(null);
  const [chatHistories, setChatHistories] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const { userId } = useAuth();
  const { sendMessage } = useWebSocket();
  const [isSwitchingChat, setIsSwitchingChat] = useState(false);
  const [lastLoadedChat, setLastLoadedChat] = useState(null);
  const [isLoadingChat, setIsLoadingChat] = useState(false);
  const [questions, setQuestions] = useState(() => {
    try {
      const stored = localStorage.getItem('chatQuestionsV1');
      return stored ? JSON.parse(stored) : [];
    } catch (e) {
      console.error('Failed to parse stored questions:', e);
      return [];
    }
  });
  const {
    messages,
    currentResponse,
    addUserMessage,
    handleIncomingMessage,
    setMessages,
  } = useChatState();

  useEffect(() => {
    try {
      localStorage.setItem('chatQuestionsV1', JSON.stringify(questions));
    } catch (e) {
      console.error('Failed to save questions to storage:', e);
    }
  }, [questions]);

  const fetchChatHistories = useCallback(async () => {
    try {
      const response = await api.get('/api/chat_histories/');
      setChatHistories(response.data);
    } catch (err) {
      console.error('Error fetching chat histories:', err);
    }
  }, []);

  useEffect(() => {
    if (userId) {
      fetchChatHistories();
    }
  }, [userId, fetchChatHistories]);

  const ensureActiveChat = useCallback(async () => {
    if (!activeChat) {
      sendMessage({
        type: 'new_chat',
        data: {}
      });
      return null;
    }
    return activeChat;
  }, [activeChat, sendMessage]);

  // Modify handleNewChatCreated
  const handleNewChatCreated = useCallback((chatId) => {
    setIsSwitchingChat(true);
    setActiveChat(chatId);
    setMessages([]);
    fetchChatHistories().finally(() => {
      setIsSwitchingChat(false);
    });
  }, [setMessages, fetchChatHistories]);

  const loadChat = useCallback(async (chatId) => {
    // Skip if already loading or same chat
    if (isLoadingChat || chatId === lastLoadedChat) {
      return;
    }

    setIsLoadingChat(true);
    setError(null);

    try {
      const response = await api.get(`/api/chat_histories/${chatId}/`);
      setActiveChat(chatId);
      setMessages(response.data.history || []);
      setLastLoadedChat(chatId);
      
      // Only send switch message if different chat
      if (chatId !== activeChat) {
        console.log('Switching chat:', chatId);
        console.log('Active chat:', activeChat);
        sendMessage({
          type: 'switch_chat',
          data: { chat_id: chatId }
        });
      }
    } catch (err) {
      console.error('Error loading chat:', err);
      setError('Failed to load chat history');
      setMessages([]);
      setActiveChat(null);
      setLastLoadedChat(null);
    } finally {
      setIsLoadingChat(false);
    }
  }, [activeChat, sendMessage, setMessages]);

  const deleteChatHistory = useCallback(async (chatId) => {
    try {
      await api.delete(`/api/chat_histories/${chatId}/`);
      setChatHistories(prev => prev.filter(chat => chat.id !== chatId));
      if (activeChat === chatId) {
        setActiveChat(null);
        setMessages([]);
      }
    } catch (error) {
      console.error('Error deleting chat:', error);
      throw error;
    }
  }, [activeChat, setMessages]);

  const reloadCurrentChat = useCallback(() => {
    if (activeChat) {
      loadChat(activeChat);
    }
  }, [activeChat, loadChat]);

  const updateChatHistories = useCallback((histories) => {
    setChatHistories(histories);
  }, []);


  // methods to work with my questionbank component

  const addQuestion = useCallback((text, type) => {
  const newQuestion = {
    id: uuidv4(),
    text: text.trim(),
    timestamp: new Date().toISOString(),
    type: type,
  };
  setQuestions(prev => [...prev, newQuestion]);
}, []);

  const deleteQuestion = useCallback((id) => {
    setQuestions(prev => prev.filter(q => q.id !== id));
  }, []);

  const moveQuestion = useCallback((id, direction) => {
    setQuestions(prev => {
      const index = prev.findIndex(q => q.id === id);
      if (index === -1) return prev;
      
      const newQuestions = [...prev];
      const newIndex = index + direction;
      
      if (newIndex < 0 || newIndex >= prev.length) return prev;
      
      [newQuestions[index], newQuestions[newIndex]] = 
      [newQuestions[newIndex], newQuestions[index]];
      
      return newQuestions;
    });
  }, []);

  const updateQuestionType = useCallback((id, newType) => {
    setQuestions(prev => prev.map(q => 
      q.id === id ? { ...q, type: newType } : q
    ));
  }, []);

  // ChatContext.js
  const constructPrompt = () => {
    const activeQuestions = questions
      .map((q, idx) => ({ ...q, originalIndex: idx + 1 }))
      .filter(q => q.isFavorite !== false);
    
    if (activeQuestions.length === 0) {
      return null;
    }
    let prompt = `You are reading a scientific paper and have the task to help in literature research, for this you got a couple of tasks and questions defined by the user which you have to follow as close as possible. Here are the tasks and questions:\n\n`;

    activeQuestions.forEach((item) => {
      prompt += `${item.type} ${item.originalIndex}: ${item.text}\n\n`;
    });

    return prompt.trim();
  };

  return (
    <ChatContext.Provider
      value={{
        activeChat,
        setActiveChat,
        messages,
        setMessages,
        currentResponse,
        chatHistories,
        isLoading,
        error,
        handleNewChatCreated,
        handleIncomingMessage,
        reloadCurrentChat,
        ensureActiveChat,
        updateChatHistories,
        addUserMessage,
        loadChat,
        deleteChatHistory,
        fetchChatHistories,
        questions,
        addQuestion,
        deleteQuestion, 
        moveQuestion,
        updateQuestionType,
        constructPrompt,
      }}
    >
      {children}
    </ChatContext.Provider>
  );
};

export const useChat = () => useContext(ChatContext);
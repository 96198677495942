// MessageList.js
import React from 'react';
import { VStack, useToken } from '@chakra-ui/react';
import MessageBubble from './MessageBubble';

export const MessageList = ({ messages, currentResponse }) => {
  // Memo für die aktuelle Response-Bubble
  const CurrentResponseBubble = React.useMemo(() => {
    if (!currentResponse) return null;
    return (
      <MessageBubble 
        message={{ content: currentResponse }} 
        isUser={false} 
        key="streaming-response"  // Stabiler Key!
      />
    );
  }, [currentResponse]);

  return (
    <VStack spacing={3} align="stretch" width="100%" p={2}>
      {messages.map((message, index) => (
        <MessageBubble 
          key={message.id || index}  // Vorzugsweise message.id verwenden
          message={message} 
          isUser={message.role === 'user'} 
        />
      ))}
      {CurrentResponseBubble}
    </VStack>
  );
};

// export const MessageList = ({ messages, currentResponse }) => {
//   const [userMessageBg, botMessageBg, primaryText, secondaryText, border, componentBg] = useToken(
//     'colors',
//     ['message-user', 'message-bot', 'text-primary', 'text-secondary', 'border-color', 'bg-component']
//   );

//   const colors = {
//     userMessageBg,
//     botMessageBg,
//     primaryText,
//     secondaryText,
//     border,
//     componentBg,
//   };

//   //console.log('MessageList colors:', colors);

//   return (
//     <VStack spacing={3} align="stretch" width="100%" p={2}>
//       {messages.map((message, index) => (
//         <MessageBubble 
//           key={index} 
//           message={message} 
//           isUser={message.role === 'user'} 
//           colors={colors}
//         />
//       ))}
//       {currentResponse && (
//         <MessageBubble 
//           message={{ content: currentResponse }} 
//           isUser={false} 
//           colors={colors}
//         />
//       )}
//     </VStack>
//   );
// };
// hooks/useScrollIndicators.js
import { useEffect, useRef } from 'react';

export const useScrollIndicators = () => {
  const elementRef = useRef(null);

  useEffect(() => {
    const element = elementRef.current;
    if (!element) return;

    const handleScroll = () => {
      const isAtTop = element.scrollTop <= 0;
      const isAtBottom = 
        Math.ceil(element.scrollHeight - element.scrollTop) <= element.clientHeight;

      element.setAttribute('data-scroll-top', (!isAtTop).toString());
      element.setAttribute('data-scroll-bottom', (!isAtBottom).toString());
    };

    element.addEventListener('scroll', handleScroll);
    handleScroll(); // Initial check

    return () => element.removeEventListener('scroll', handleScroll);
  }, []);

  return elementRef;
};

// components/SendButton.js
import React from 'react';
import { IconButton } from '@chakra-ui/react';
import { ReactComponent as SendIconSvg } from '../../icons/chat_icon.svg';

const SendIcon = ({ size, ...props }) => (
  <SendIconSvg width={size} height={size} {...props} />
);

export const SendButton = ({ sendMessage, iconSize, buttonWidth }) => {
  return (
    <IconButton
      icon={<SendIcon size={iconSize} />}
      onClick={sendMessage}
      aria-label="Send message"
      bg="button-primary"
      color="button-text"
      _hover={{ 
        bg: "button-primary-hover",
        transform: "translateY(-1px)"
      }}
      _active={{ 
        bg: "button-primary-hover",
        transform: "translateY(0)"
      }}
      borderRadius="full"
      width={buttonWidth}
      height={`calc(${iconSize} + 4px)`}
      minWidth={`calc(${iconSize} + 16px)`}
      padding="0"
      transition="all 0.2s ease-in-out"
    />
  );
}

// // components/SendButton.js
// import React from 'react';
// import { IconButton, useColorModeValue, useTheme } from '@chakra-ui/react';
// import { ReactComponent as SendIconSvg } from '../../icons/chat_icon.svg';

// const SendIcon = ({ size, ...props }) => (
//   <SendIconSvg width={size} height={size} {...props} />
// );

// export const SendButton = ({ sendMessage, iconSize, buttonWidth }) => {
//   const theme = useTheme();
//   const buttonBg = useColorModeValue('button-primary', 'button-primary');
//   const buttonHoverBg = useColorModeValue('button-primary-hover', 'button-primary-hover');
//   const buttonColor = useColorModeValue('button-text', 'button-text');

//   return (
//     <IconButton
//       icon={<SendIcon size={iconSize} />}
//       onClick={sendMessage}
//       aria-label="Send message"
//       bg={buttonBg}
//       color={buttonColor}
//       _hover={{ 
//         bg: buttonHoverBg,
//       }}
//       _active={{ 
//         bg: buttonHoverBg,
//       }}
//       borderRadius="full"
//       width={buttonWidth}
//       height={`calc(${iconSize} + 4px)`}
//       minWidth={`calc(${iconSize} + 16px)`}
//       padding="0"
//       transition="all 0.2s ease-in-out"
//     />
//   );
// }
// components/ChatInputArea/InputArea.js
import React, { useState } from 'react';
import { Box, Flex } from '@chakra-ui/react';
import { SendButton } from './SendButton';
import { PlaceholderText } from './PlaceholderText';
import { ChatTextarea } from './ChatTextArea';
import '../../App.css';
import { UploadButton } from '../DocumentManager/components/UploadButton';

export const InputArea = ({ inputMessage, setInputMessage, sendMessage, onFocusChange }) => {
  const [isInputFocused, setIsInputFocused] = useState(false);
  
  const handleFocus = () => {
    onFocusChange?.(true);
  };

  const handleBlur = () => {
    onFocusChange?.(false);
  };
  
  const iconSize = "40px";
  const buttonWidth = `calc(${iconSize} + 2.5rem)`;
  const buttonWidthAddPDF = `calc(${iconSize})`;

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      sendMessage();
    }
  };

  return (
    <Box 
      p={4} 
      bg="bg-component"
      borderColor="border-color"
      position="relative"
      borderTop="none"
      backdropFilter="blur(10px)"
    >
      <Flex position="relative" alignItems="center">
        {/* Upload Button */}
        <Box 
          position="absolute" 
          left={2} 
          bottom={3.5} 
          zIndex={4}
        >
          <UploadButton 
            size="sm"
            onUploadComplete={(result) => {
              console.log('Upload completed:', result);
            }}
          />
        </Box>

        {/* Chat Input Area */}
        <Box 
          flex={1}
          position="relative"
          onFocus={handleFocus}
          onBlur={handleBlur}
        >
          <ChatTextarea 
            inputMessage={inputMessage}
            setInputMessage={setInputMessage}
            handleKeyPress={handleKeyPress}
            pl={`calc(${buttonWidthAddPDF} + 0.7rem)`}
            pr={`calc(${buttonWidth} + 1.25rem)`}
            bg="input-bg"
            borderColor="input-border"
            color="text-primary"
            _hover={{
              borderColor: "input-focus"
            }}
            _focus={{
              borderColor: "input-focus",
              boxShadow: "none"
            }}
          />
          {!inputMessage && <PlaceholderText />}
        </Box>

        {/* Send Button */}
        <Box 
          position="absolute" 
          right={4} 
          bottom={2} 
          zIndex={4}
        >
          <SendButton 
            sendMessage={sendMessage} 
            iconSize={iconSize} 
            buttonWidth={buttonWidth} 
          />
        </Box>
      </Flex>
    </Box>
  );
}
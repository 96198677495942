export const parseContent = (content, blockIdsRef) => {
    if (!content) return [];
    
    const filteredContent = content.replace(/<pdf_prompt>[\s\S]*?<\/pdf_prompt>/g, '');
    const parts = [];
    let lastIndex = 0;
    
    const thinkRegex = /<think>([\s\S]*?)(?:<\/think>|$)/g;
    let match;
  
    while ((match = thinkRegex.exec(filteredContent)) !== null) {
      const fullMatch = match[0];
      const thinkContent = match[1];
      const start = match.index;
      
      let blockId = blockIdsRef.current.get(start);
      if (!blockId) {
        blockId = `think-${start}-${Date.now()}`;
        blockIdsRef.current.set(start, blockId);
      }
      
      if (start > lastIndex) {
        parts.push({
          type: 'text',
          content: filteredContent.substring(lastIndex, start),
          key: `text-${lastIndex}`
        });
      }
  
      parts.push({
        type: 'think',
        content: thinkContent,
        key: blockId,
        isComplete: fullMatch.endsWith('</think>')
      });
  
      lastIndex = start + fullMatch.length;
    }
  
    if (lastIndex < filteredContent.length) {
      parts.push({
        type: 'text',
        content: filteredContent.substring(lastIndex),
        key: `text-${lastIndex}`
      });
    }
  
    return parts;
  };
  
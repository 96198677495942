// components/Styles/ScrollbarStyle.js
export const scrollbarStyle = {
  // Basis-Scrollbar-Einstellungen
  '&::-webkit-scrollbar': {
    width: '4px',
    height: '4px',
  },
  // Vertikale Track
  '&::-webkit-scrollbar-track:vertical': {
    background: 'transparent',
    borderRadius: '4px',
  },
  // Horizontale Track
  '&::-webkit-scrollbar-track:horizontal': {
    background: 'transparent',
    borderRadius: '4px',
  },
  // Vertikale Thumb
  '&::-webkit-scrollbar-thumb:vertical': {
    background: '#8B5CF6',
    borderRadius: '4px',
    opacity: 0.3,
  },
  // Horizontale Thumb
  '&::-webkit-scrollbar-thumb:horizontal': {
    background: '#8B5CF6',
    borderRadius: '4px',
    opacity: 0.3,
  },
  // Hover-States
  '&::-webkit-scrollbar-thumb:vertical:hover': {
    background: '#9F7AEA',
    opacity: 0.5,
  },
  '&::-webkit-scrollbar-thumb:horizontal:hover': {
    background: '#9F7AEA',
    opacity: 0.5,
  }
};




// // components/Styles/ScrollbarStyle.js
// export const scrollbarStyle = {
//   '&::-webkit-scrollbar': {
//     width: '4px',
//     height: '4px',
//   },
//   '&::-webkit-scrollbar-track': {
//     background: 'transparent',
//     borderRadius: '4px',
//   },
//   '&::-webkit-scrollbar-thumb': {
//     background: '#8B5CF6',
//     borderRadius: '4px',
//     opacity: 0.3,
//   },
//   '&::-webkit-scrollbar-thumb:hover': {
//     background: '#9F7AEA',
//     opacity: 0.5,
//   }
// };
// components/CorporateButtons/LogoutButton.js
import React from 'react';
import { Button, Icon, Text, Box, useColorModeValue } from '@chakra-ui/react';

const LogoutIcon = (props) => (
  <Icon viewBox="0 0 24 24" {...props}>
    <path
      fill="currentColor"
      d="M16 17v-3H9v-4h7V7l5 5-5 5M14 2a2 2 0 012 2v2h-2V4H5v16h9v-2h2v2a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2h9z"
    />
  </Icon>
);

const LogoutButton = ({ onClick, isCollapsed }) => {
  const bgColor = useColorModeValue('button-primary', 'button-primary');
  const hoverBgColor = useColorModeValue('button-primary-hover', 'button-primary-hover');
  const textColor = useColorModeValue('button-text', 'button-text');
  const borderColor = useColorModeValue('button-border', 'button-border');

  return (
    <Button
      onClick={onClick}
      bg={bgColor}
      color={textColor}
      border="1px solid"
      borderColor={borderColor}
      borderRadius={isCollapsed ? "12px" : "20px"}
      height={isCollapsed ? "40px" : "40px"}
      width={isCollapsed ? "40px" : "auto"}
      padding={isCollapsed ? "8px" : "0 12px"}
      display="flex"
      alignItems="center"
      justifyContent="center"
      fontFamily="body"
      fontSize="14px"
      fontWeight="500"
      letterSpacing="0.25px"
      transition="all 0.2s"
      _hover={{
        boxShadow: '0 1px 2px 0 rgba(255, 255, 255, .30), 0 1px 3px 1px rgba(255, 255, 255, .15)',
        bg: hoverBgColor,
        transform: isCollapsed ? "translateY(-1px)" : "none"
      }}
      _active={{
        bg: hoverBgColor,
        transform: "none"
      }}
    >
      <LogoutIcon 
        boxSize={isCollapsed ? "20px" : "16px"}
        marginRight={isCollapsed ? "0" : "12px"} 
      />
      {!isCollapsed && <Text>Sign out</Text>}
    </Button>
  );
};

export default LogoutButton;

// import React from 'react';
// import { Button, Icon, Text, Box, useColorModeValue } from '@chakra-ui/react';

// const LogoutIcon = (props) => (
//   <Icon viewBox="0 0 24 24" {...props}>
//     <path
//       fill="currentColor"
//       d="M16 17v-3H9v-4h7V7l5 5-5 5M14 2a2 2 0 012 2v2h-2V4H5v16h9v-2h2v2a2 2 0 01-2 2H5a2 2 0 01-2-2V4a2 2 0 012-2h9z"
//     />
//   </Icon>
// );

// const LogoutButton = ({ onClick }) => {
//   const bgColor = useColorModeValue('button-primary', 'button-primary');
//   const hoverBgColor = useColorModeValue('button-primary-hover', 'button-primary-hover');
//   const textColor = useColorModeValue('button-text', 'button-text');
//   const borderColor = useColorModeValue('button-border', 'button-border');

//   return (
//     <Button
//       onClick={onClick}
//       bg={bgColor}
//       color={textColor}
//       border="1px solid"
//       borderColor={borderColor}
//       borderRadius="20px"
//       height="40px"
//       padding="0 12px"
//       fontFamily="body"
//       fontSize="14px"
//       fontWeight="500"
//       letterSpacing="0.25px"
//       transition="background-color .218s, border-color .218s, box-shadow .218s"
//       _hover={{
//         boxShadow: '0 1px 2px 0 rgba(255, 255, 255, .30), 0 1px 3px 1px rgba(255, 255, 255, .15)',
//         bg: hoverBgColor
//       }}
//       _active={{
//         bg: hoverBgColor
//       }}
//       _disabled={{
//         opacity: 0.38,
//         cursor: 'default',
//         bg: `${bgColor}61`,
//         borderColor: `${borderColor}1F`
//       }}
//     >
//       <Box display="flex" alignItems="center">
//         <LogoutIcon boxSize="20px" marginRight="12px" />
//         <Text>Sign out</Text>
//       </Box>
//     </Button>
//   );
// };

// export default LogoutButton;